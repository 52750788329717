.tabContainer {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 10000;
}

.unselectParticipate {
  width: 22px;
  height: 22px;
  background: url(../../assets/participate_unselect.png) center center / 21px
    21px no-repeat;
}

.selectParticipate {
  width: 22px;
  height: 22px;
  background: url(../../assets/participate_select.png) center center / 21px 21px
    no-repeat;
}

.unselectDating {
  width: 22px;
  height: 22px;
  background: url(../../assets/dating_unselect.png) center center / 21px
    21px no-repeat;
}

.selectDating {
  width: 22px;
  height: 22px;
  background: url(../../assets/dating_select.png) center center / 21px 21px
    no-repeat;
}

.unselectMyPage {
  width: 22px;
  height: 22px;
  background: url(../../assets/mypage_unselect.png) center center / 21px
    21px no-repeat;
}

.selectMyPage {
  width: 22px;
  height: 22px;
  background: url(../../assets/mypage_select.png) center center / 21px 21px
    no-repeat;
}
