.chatLinkText {
  width: 85%;
  height: 18px;
  line-height: normal;
  font-size: 13px;
  text-align: center;
  letter-spacing: 1px;
  text-decoration-line: underline;
  color: #c0a76e;
  white-space: nowrap;
}

.chatLink {
  margin: 0 auto 15px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

