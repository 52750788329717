.wrapper {
  display: flex;
  flex-flow: column nowrap;
  background-color: #f1f1f1;
  padding: 60px 0 20px;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.navBar {
  position: absolute;
  height: 60px;
  line-height: normal;
  font-size: 12px;
  text-align: center;
  color: #c0a76e;
}

.navIcon {
  width: 25px;
  height: 25px;
}

.containerFirstBtn {
  display: flex;
  margin-left: 15px;
  margin-right: 15px;
}

.horizontalTextLink {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.bottomTextLink1 {
  width: 100%;
}

.bottomTextLink2 {
  width: 100%;
}
