:root {
  --NotoSans: 'Noto Sans Japanese', sans-serif;
}

html,
body,
#app,
#app > div {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--NotoSans);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
  -webkit-overflow-scrolling: touch;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
}

:global(.am-tab-bar-bar::before) {
  background-color: #111 !important;
}
