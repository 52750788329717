.chatLinkTextCustom {
  line-height: normal;
  font-size: 13px;
  text-align: center;
  letter-spacing: 1px;
  color: hsl(42, 39%, 59%);
  box-shadow: -2px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  padding: 5px 0px 5px 0px;
  height: 30px;
  font-weight: 500;
}

.chatLinkCustom {
  margin: 8px auto 0px;
  flex-flow: column nowrap;
  align-items: center;
  text-align: center;
  width: 300px;
  background-color: #F1F1F1;;
}

.chatLinkCustomLeft {
  padding-right: 5px;
  padding-left: 24px;
}

.chatLinkCustomRight {
  padding-right: 24px;
  padding-left: 5px;
}

.chatLinkMaxWidth {
  margin: 8px 0px 8px 0px;
  flex-flow: column nowrap;
  align-items: center;
  text-align: center;
  width: 100%;
}



/* iPhone X */
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .chatLinkTextCustom {
    padding: 5px 0px 5px 0px;
  }
}

/* iPhone 8  */
@media only screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
  .chatLinkTextCustom {
    padding: 5px 0px 5px 0px;
  }
}

/* iPhone 8 Plus */
@media only screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) {
  .chatLinkTextCustom {
    padding: 5px 0px 5px 0px;
  }
}


/* iphone 5 */
@media screen and (device-aspect-ratio: 40/71) {
  .chatLinkTextCustom  {
    font-size: 11px;
  }

  .chatLinkCustom {
    width: 100%;
    padding-left: 23px;
    padding-right: 23px;
  }
  
}

