.chatTextInput {
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  margin: auto 23px 0;
  flex: 0 0 auto;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 149px;
  padding: 14px 60px 14px 26px;
  background-color: #f1f1f1;
}

.textInputBox {
  background-color: #f1f1f1;
  resize: none;
  border: 0;
  padding: 0;
  width: 100%;
}

.textInputBox::placeholder {
  width: 135px;
  height: 21.5px;
  line-height: normal;
  font-size: 14px;
  letter-spacing: 1px;
  color: #a0a0a0;
  font-weight: 500;
}

.sendButtonText {
  width: 31px;
  height: 21px;
  line-height: normal;
  font-size: 14px;
  text-align: right;
  letter-spacing: 1px;
  color: #a0a0a0;
  position: absolute;
  right: 26px;
  top: 13px;
}
