.userTextMessage {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 15px;
  position: relative;
}

.chatUserTextBubble {
  min-height: min-content;
  max-width: 263px;
  background: #c0a76e;
  border-radius: 26px;
  padding: 11px 14px 12px;
  word-wrap: break-word;
  line-height: normal;
  font-size: 13px;
  letter-spacing: 1px;
}

.chatUserTimestamp {
  line-height: normal;
  font-size: 10px;
  text-align: right;
  letter-spacing: 1px;
  color: #000000;
  position: absolute;
  right: 20px;
  padding-bottom: 5px;
  visibility: hidden;
}

