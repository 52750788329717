.container {
  flex: 10;
  flex-direction: column;
  width: 85%;
  height: auto;
  background-color: #e5e5e5;
  z-index: 999999;
  border-radius: 8px;
  padding-left: 21px;
  padding-right: 21px;
}
.container-overlay {
  position: fixed; /* Sit on top of the page content */
  display: inline; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999998;
}
.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.header {
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}
.content {
  height: auto;
  margin-bottom: 46;
}
.footer {
  height: auto;
  margin-top: 40px;
}

.title-container {
}

.title {
  line-height: normal;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
}

.message-container {
  margin-top: 25px;
}

.message {
  line-height: normal;
}

.message-one-line {
  line-height: normal;
  text-align: center;
}

.message-multiple-lines {
  line-height: normal;
  text-align: left;
}

.button-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 46px;
}

.button {
  display: flex;
  height: 60px;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #c0a76e;
  color: #000;
  font-size: 16.6px;
  line-height: normal;
  text-align: center;
  letter-spacing: 1px;
  font-weight: 400;
}

.button-ok {
  color: #000;
  font-size: 16.6px;
  line-height: normal;
  font-size: 16.6px;
  text-align: center;
  letter-spacing: 1px;
  font-weight: 400;
}

.button-positive {
  margin-bottom: 16px;
}

.button-negative {
  background-color: #a0a0a0;
}

.button-neutral {
}
/* Close button
*/
.close-button-container {
  position: absolute;
  right: -15px;
  top: -15px;
  width: 31px;
  height: 31px;
  opacity: 1;
  background-color: #e5e5e5;
  z-index: 1010;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.close-button {
  position: absolute;
  width: 27px;
  height: 27px;
  border-radius: 13.5px;
  opacity: 1;
  background-color: #c0a76e;
  z-index: 1010;
  display: flex;
  align-items: center;
  justify-content: center;
}
.close-button:hover {
  opacity: 1;
}
.close-button:before,
.close-button:after {
  position: absolute;
  content: ' ';
  height: 14px;
  width: 1px;
  background-color: white;
}
.close-button:before {
  transform: rotate(45deg);
}
.close-button:after {
  transform: rotate(-45deg);
}

/* iPhone X */
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .title {
    font-size: 18px;
    font-weight: bold;
  }

  .message {
    font-size: 14px;
    font-weight: 300;
  }

  .button {
    font-size: 15.6px;
    font-weight: 400;
  }
}

/* iPhone 8  */
@media only screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
  .title {
    font-size: 16px;
    font-weight: 600;
  }

  .message {
    font-size: 14px;
    font-weight: 300;
  }

  .button {
    font-size: 15.6px;
    font-weight: 400;
  }
}

/* iPhone 8 Plus */
@media only screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) {
  .container {
    width: 80%;
  }

  .title {
    font-size: 18px;
    font-weight: bold;
  }

  .message {
    font-size: 15px;
    font-weight: 300;
  }

  .button {
    font-size: 16.6px;
    font-weight: 400;
  }
}



/* iphone 5 */
@media screen and (device-aspect-ratio: 40/71) {
  .title {
    font-size: 15px;
  }
  .message-container {
    margin-top: 21px;
  }
  .message {
    font-size: 13px;
  }
  .button {
    font-size: 15px;
    height: 54px;
  }
  .header {
    margin-top: 20px;
  }
  .footer {
    margin-top: 20px;
  }
  .button-container {
    margin-bottom: 33px;
  }
}
